<template>
  <div class="my-lottery">
    <div class="top-header" :class="{ active: havePrize }">
      <template v-if="havePrize">
        <div class="check-btn" @click="handleCheck">{{ my.checkBtn }}</div>
      </template>
      <template v-else>
        <div class="no-prize">{{ my.noPrize }}</div>
      </template>
    </div>
    <RoundHistory
      :roundList="roundList"
      @handleToRound="handleToRound"
    ></RoundHistory>
    <RewardPop v-if="popShow" @close="handleCheck"></RewardPop>
  </div>
</template>
<script>
import RoundHistory from './components/RoundHistory.vue'
import RewardPop from './components/RewardPop.vue'
export default {
  components: { RoundHistory, RewardPop },
  data () {
    return {
      popShow: false,
      havePrize: true,
      roundList: [
        {
          id: 1,
          roundNo: '1235',
          date: '2022-03-28',
          time: '08:00',
          quantity: 345,
          isWin: false
        }, {
          id: 2,
          roundNo: '1234',
          date: '2022-03-28',
          time: '08:00',
          quantity: 355,
          isWin: true
        }, {
          id: 3,
          roundNo: '1233',
          date: '2022-03-28',
          time: '08:00',
          quantity: 342,
          isWin: true
        }, {
          id: 4,
          roundNo: '1232',
          date: '2022-03-28',
          time: '08:00',
          quantity: 375,
          isWin: false
        }
      ]
    }
  },
  methods: {
    changeTopBg () {
      const wrapper = document.getElementsByClassName('wrapper')[0]
      // console.log(wrapper.style)
      if (wrapper) {
        wrapper.style.background = this.havePrize ? '#A85FFC' : '#BEBEBE'
      }
    },
    resetTopBg () {
      const wrapper = document.getElementsByClassName('wrapper')[0]
      if (wrapper) {
        wrapper.style.background = ''
      }
    },
    handleCheck () {
      this.popShow = !this.popShow
    },
    handleToRound (item) {
      const url = `/lotteryHistroy?roundId=${item.id}`
      this.$gbUtils.handleToPage(url)
    }
  },
  computed: {
    my () {
      return this.$t('lottery.my')
    }
  },
  mounted () {
    this.changeTopBg()
  },
  beforeDestroy () {
    this.resetTopBg()
  }
}
</script>
<style lang="scss" scoped>
.my-lottery {
  .top-header {
    width: 100%;
    height: 106px;
    background: linear-gradient(180deg, #bebebe 0%, #5f5f5f 100%);
    position: relative;
    color: #ffffff;
    font-weight: bold;
    .no-prize {
      word-wrap: break-word;
      white-space: pre-wrap;
      text-align: center;
      font-size: 20px;
      padding: 27px 0 0;
      box-sizing: border-box;
    }
    &.active {
      background: linear-gradient(180deg, #a85ffc 0%, #54307e 100%);
      position: relative;
      padding: 34px 0 0 0;
      box-sizing: border-box;
      &::after {
        content: url('../../../static/img/lottery/topBg.png');
        width: 100%;
        height: 106px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
      }
      .check-btn {
        width: 120px;
        height: 40px;
        background: rgba(253, 176, 57, 1);
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 10px;
        line-height: 40px;
        font-size: 15px;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        text-align: center;
        margin: auto;
        position: relative;
        z-index: 1;
        cursor: pointer;
      }
    }
  }
}
</style>
