<template>
  <div>
    <div class="round-wrapper">
      <div class="round-body">
        <div class="title">{{ my.Round }}</div>
        <div class="table">
          <div class="table-th">
            <div class="td1">#</div>
            <div class="td2">{{ my.date }}</div>
            <div class="td3">{{ my.quantity }}</div>
            <div class="td4">{{ my.results }}</div>
            <div class="td5">{{ my.details }}</div>
          </div>
          <div class="tr" v-for="item in roundList" :key="item.id">
            <div class="td1">{{item.roundNo}}</div>
            <div class="td2">
              <div class="date">{{item.date}}</div>
              <div class="time">{{item.time}}</div>
            </div>
            <div class="td3">{{item.quantity || 0}}</div>
            <div class="td4">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-win" v-if="item.isWin"></use>
                <use xlink:href="#icon-notWin" v-else></use>
              </svg>
            </div>
            <div class="td5" @click="handleToRound(item)">
              <span
                >{{ my.Round }}<span v-html="$t('lottery.pop.space')"></span
                >{{ my.details }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RoundHistory',
  props: {
    roundList: {
      default () {
        return []
      }
    }
  },
  data () {
    return {
      havePrize: true
    }
  },
  computed: {
    my () {
      return this.$t('lottery.my')
    }
  },
  methods: {
    handleToRound (item) {
      this.$emit('handleToRound', item)
    }
  },
  mounted () {}
}
</script>

<style scoped lang="scss">
.round-wrapper {
  padding: 20px 15px;
  box-sizing: border-box;
  .round-body {
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    min-height: 500px;
    border-radius: 10px;
    overflow: hidden;
    .title {
      height: 60px;
      line-height: 60px;
      background: rgba(238, 237, 243, 1);
      padding: 0 20px;
      box-sizing: border-box;
      color: #2a2b33;
      font-size: 18px;
    }
    .table {
      padding: 0 17px;
      box-sizing: border-box;
    }
    .table-th {
      display: flex;
      align-items: center;
      height: 56px;
      line-height: 56px;
      font-size: 15px;
      font-weight: bold;
      line-height: 22px;
      color: #2a2b33;
      border-bottom: 1px dashed #e3e3e3;
    }
    .td1 {
      width: 18%;
    }
    .td2 {
      width: 30%;
    }
    .td3 {
      width: 19%;
    }
    .td4 {
      width: 15%;
    }
    .td5 {
      flex: 1;
      text-align: right;
      cursor: pointer;
    }
    .tr {
      display: flex;
      font-size: 12px;
      color: #4c5968;
      margin-top: 20px;
      .icon {
        width: 20px;
        height: 15px;
      }
      .date {
        font-weight: bold;
      }
      .td3 {
        font-weight: bold;
        font-size: 15px;
      }
      .td5 {
        span {
          color: #7b8fbb;
          border-bottom: 1px solid #7b8fbb;
        }
      }
    }
  }
}
</style>
