<template>
  <VanPopup
    v-model="popupShow"
    @click-overlay="closePop"
    style="width: 93%; max-width: 380px; overflow-y: visible"
    round
    get-container="content"
  >
    <div id="header">
      <div class="title">{{ pop.reward }}</div>
      <img src="../../../../static/img/lottery/lottery-logo.png" alt="" />
      <div class="close-wrapper">
        <div class="close-icon" @click="closePop">✕</div>
      </div>
    </div>
    <div id="content">
      <div class="title">{{ pop.yourReward }}</div>
      <div class="meb">412512.65 <span>MEB</span></div>
      <div class="gift">
        <img src="../../../../static/img/lottery/lottery-gift.png" alt="" />
      </div>
      <div class="usdt">~5834USD</div>
      <div class="round">{{ pop.round }} #396</div>
      <div class="button">{{ pop.receive }}</div>
    </div>
  </VanPopup>
</template>

<script>
export default {
  name: '',
  data () {
    return {
      popupShow: true
    }
  },
  computed: {
    pop () {
      return this.$t('lottery.pop')
    }
  },
  methods: {
    // 关闭弹窗
    closePop () {
      this.$emit('close')
    }
  },
  mounted () {

  }
}
</script>

<style scoped lang="scss">
#header {
  display: flex;
  padding: 0 30px;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  background: rgba(238, 237, 243, 0.39);
  border-radius: 16px;
  font-weight: bold;
  font-size: 18px;
  color: #2a2b33;
  position: relative;
  img {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -50px;
    z-index: 9999;
  }
  .close-icon {
    background-color: #66eab9 !important;
  }
}
#content {
  text-align: center;
  padding: 30px;
  .title {
    font-size: 18px;
    color: #16172a;
  }
  .meb {
    padding-top: 20px;
    font-weight: bold;
    font-size: 35px;
    color: #a85ffc;
  }
  .gift {
    padding-top: 30px;
    img {
      width: 68px;
      height: 68px;
    }
  }
  .usdt,
  .round {
    padding-top: 15px;
    font-size: 12px;
    color: #4c5968;
  }
  .button {
    margin-top: 20px;
    width: 100%;
    background: #66eab9;
    border-radius: 15px;
    height: 50px;
    line-height: 50px;
    color: #2a2b33;
    font-weight: bold;
    font-size: 15px;
  }
}
</style>
